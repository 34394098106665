import React, { useMemo, useState, useContext, useRef, useEffect } from 'react';
import { AssetVersionItemDto, ImageMetadataDto } from '@api/Api';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import PanelAction from '@pages/MediaViewer/AssetViewer/PanelAction';
import AssetInfo from '@pages/MediaViewer/AssetViewer/AssetInfo';
import './ImageCard.less';
import PlaybackPreparingLoader from '@pages/MediaViewer/AssetViewer/PlaybackPreparingLoader';
import Transparent from '@assets/images/chroma-key.png';
import {
  setTurnoverDegree,
  setZoomFactorAction
} from '@redux/actions/mediaViewerAction';
import { nonNull } from '@helpers/non-null';
import { FabricContext } from '@context/FabricContext';
import FabricCanvas from '@pages/MediaViewer/AssetViewer/Canvas/Canvas';

interface IImageCardProps {
  isExpand: boolean;
  setIsExpand(val: boolean): void;
  onReady: (a?: any) => any;
  asset: AssetVersionItemDto;
  isPreparing: boolean;
}

let IS_MOUSE_DOWN = false;
let OFFSET = [0, 0];

function ImageCard({
  isExpand,
  setIsExpand,
  onReady,
  asset,
  isPreparing
}: IImageCardProps) {
  const fabricContext = useContext(FabricContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const canvasContainerRef = useRef<HTMLImageElement>(null);

  const dispatch = useDispatch();
  const isToolsExpanded = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isToolsExpanded
  );
  const isSelectedCanvasObject = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isSelectedCanvasObject
  );
  const zoomFactor = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.zoomFactor
  );
  const turnoverDegree = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.turnoverDegree
  );

  const fileUrl = useMemo(() => {
    const { proxy } = asset.metadata as ImageMetadataDto;
    return proxy.url || '';
  }, [asset]);

  const [imageSize, setImageSize] = useState({
    width: 1,
    height: 1
  });

  useEffect(() => {
    const container = containerRef?.current;
    const image = imageRef?.current;
    if (!container || !image) return;
    const { height, width } = imageSize;
    const containerHeight = container.offsetHeight;
    const containerWidth = container.offsetWidth;
    if (height > containerHeight || width > containerWidth) {
      const scaleHeight = containerHeight / (height / 100);
      const scaleWidth = containerWidth / (width / 100);
      const zoomFactor = scaleHeight > scaleWidth ? scaleWidth : scaleHeight;

      dispatch(setZoomFactorAction(zoomFactor / 100));
    }
  }, [dispatch, imageSize]);

  useEffect(() => {
    const image = imageRef?.current;
    const container = canvasContainerRef?.current;
    if (!image || !container || !fabricContext.canvas) return;
    const rect = image.getBoundingClientRect();

    container.style.width = `${rect.width}px`;
    container.style.height = `${rect.height}px`;
    fabricContext.canvas.setWidth(rect.width);
    fabricContext.canvas.setHeight(rect.height);
    fabricContext.canvas.setViewportTransform([
      zoomFactor * 10,
      0,
      0,
      zoomFactor * 10,
      0,
      0
    ]);
    canvasContainerRef.current.style.translate = `-${Math.round(
      rect.width / 2
    )}px -${Math.round(rect.height / 2)}px`;
  }, [imageSize, zoomFactor, fabricContext.canvas, turnoverDegree]);

  const onMouseDown = (e: any) => {
    if (!imageRef.current) return;
    IS_MOUSE_DOWN = true;
    OFFSET = [
      imageRef.current.offsetLeft - (e.clientX || e.touches?.[0]?.clientX || 0),
      imageRef.current.offsetTop - (e.clientY || e.touches?.[0]?.clientY || 0)
    ];
  };
  const onMouseUp = () => {
    IS_MOUSE_DOWN = false;
  };
  const onMouseMove = (e: any) => {
    if (isToolsExpanded || isSelectedCanvasObject) return;
    if (!imageRef.current || !canvasContainerRef.current) return;
    if (!IS_MOUSE_DOWN) return;
    e.preventDefault();

    const mousePosition = {
      x: e.clientX || e.touches?.[0]?.clientX,
      y: e.clientY || e.touches?.[0]?.clientY
    };
    imageRef.current.style.left = `${mousePosition.x + OFFSET[0]}px`;
    imageRef.current.style.top = `${mousePosition.y + OFFSET[1]}px`;
    canvasContainerRef.current.style.left = `${mousePosition.x + OFFSET[0]}px`;
    canvasContainerRef.current.style.top = `${mousePosition.y + OFFSET[1]}px`;
  };
  const onMouseLeave = () => {
    IS_MOUSE_DOWN = false;
  };

  return (
    <div className="media_viewer_image" ref={containerRef}>
      <div className="media_viewer_image__container">
        <img
          ref={imageRef}
          onLoad={() => {
            const img = nonNull(imageRef.current);
            setImageSize({
              width: img.naturalWidth,
              height: img.naturalHeight
            });
            onReady();
          }}
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          onTouchEnd={onMouseLeave}
          src={fileUrl}
          alt="#"
          style={{
            ...imageSize,
            transform: `translateX(-50%) translateY(-50%)  rotate(${turnoverDegree}deg) scale(${zoomFactor})`,
            backgroundImage: `url("${Transparent}")`
          }}
        />
        {isPreparing && <PlaybackPreparingLoader />}

        <div
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          onTouchEnd={onMouseLeave}
          ref={canvasContainerRef}
          className="media_viewer_image__canvas"
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <FabricCanvas />
        </div>
        <PanelAction
          zoom={zoomFactor}
          setZoom={(v) => {
            const image = imageRef?.current;
            const container = canvasContainerRef?.current;
            if (!image || !container) return;
            const rect = image.getBoundingClientRect();
            container.style.width = `${rect.width}px`;
            container.style.height = `${rect.height}px`;

            dispatch(setZoomFactorAction(v));
          }}
          isExpand={isExpand}
          setIsExpand={(v) => {
            if (!imageRef.current || !canvasContainerRef.current) return;
            imageRef.current.style.left = '50%';
            imageRef.current.style.top = '50%';
            canvasContainerRef.current.style.left = '50%';
            canvasContainerRef.current.style.top = '50%';
            setIsExpand(v);
          }}
          setRotate={(v) => {
            const rt = turnoverDegree + v === 360 ? 0 : turnoverDegree + v;

            dispatch(setTurnoverDegree(rt));
          }}
        />
      </div>
      {isExpand && (
        <div className="media_viewer_information">
          <AssetInfo />
        </div>
      )}
    </div>
  );
}

export default ImageCard;
